import "swiper/css";
import "swiper/css/pagination";
import { A11y, Autoplay, EffectCreative, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import axios from "axios";
import { useInView } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "../Context/useTheme";

const BrandSlider = ({ setisLight }) => {
  const [brandImages, setBrandImages] = useState([]);
  const fetchBrandImages = async () => {
    try {
      const response = await axios.get(
        "https://premiumidapi.dev-sh.xyz/api/brand-section"
      );

      if (response?.data?.data?.length > 0) {
        const data = response?.data?.data?.reverse();
        const result = [];
        for (let i = 0; i < data.length; i += 8) {
          result.push(data.slice(i, i + 8));
        }
        setBrandImages(result);
      }
    } catch (error) {
      console.error("Error fetching brand images:", error);
    }
  };
  const targetRef = useRef(null);
  const { toggleTheme, toggleisHome } = useTheme();

  const sectionViewCheck = useInView(targetRef);
  useEffect(() => {
    if (sectionViewCheck) {
      toggleTheme("light");
      toggleisHome(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionViewCheck]);

  useEffect(() => {
    fetchBrandImages();
  }, []);
  console.log(brandImages, "<===brandImages");
  return (
    <>
      <div
        ref={targetRef}
        className="container-fluid  brand-slider bg-dark section-padding custom-padding "
      >
        <Swiper
          grabCursor={true}
          // effect={"creative"}
          autoplay={{
            delay: 3600,
          }}
          speed={1000}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: ["-130%", 0, 0],
            },
            next: {
              translate: ["100%", "-100%", "-100%"],
              opacity: 0,
            },
          }}
          modules={[Pagination, A11y, EffectCreative, Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ clickable: true }}
          className="pb-5"
        >
          {brandImages.map((imageGroup, index) => (
            <SwiperSlide key={index}>
              <div className="brand-grids ">
                {imageGroup.map((image, idx) => (
                  <div className="slider-image" key={idx}>
                    <img
                      src={image.image}
                      alt={`Brand-${idx}`}
                      className="img-fluid"
                    />
                  </div>
                ))}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default BrandSlider;
